import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import {
  getAllWorkspaces,
  setWorkspace
} from "../../reducers/workspaceReducer";
import {
  getReports,
  saveReportDescs,
  filterReports,
  saveImg,
  updateReport,
  setSelectedReport
} from "../../reducers/reportReducer";
import "./style.css";
import Checkbox from "rc-checkbox";
import ReportCheckbox from "./reportCheckbox";
import { ReportIcon, LeftArrow } from "../../lib/icons";
import { toast } from "react-toastify";
import axios from "axios";

class ReportMetadata extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedClient: null,
      selectedWorkspace: null,
      reloadImg: false,
      selectedAllowSave: false,
      selectedAllowDelete: false,
      selectedAllowClone: false,
      selectedShowFilterPane: false,
      selectedShowBookmarks: false,
      imgSrc: "",
      imgWidth: 0,
      imgHeight: 0,
      featured: false,
      x: 0,
      left: 0,
      y: 0,
      top: 0,
      height: 0,
      width: 0,
      scaleFactor: 0.75,
      hideWorkspace: false,
      disableReportCreation: false,
      enableWorkspaceBookmarks: false,
      enablePersonalWorkspaceBookmarks: false,
      loadWorkspaceAttribs: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.removeImgHandler = this.removeImgHandler.bind(this);
    this.uploadCallback = this.uploadCallback.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.applyToWorkspace = this.applyToWorkspace.bind(this);
    this.workspaceAlias = React.createRef();
    this.createThumbnails = this.createThumbnails.bind(this);
  }
  handleChange = selectedOption => {
    this.props.setWorkspace(selectedOption.value);
    this.setState({
      selectedWorkspace: selectedOption,
      loadWorkspaceAttribs: true,
      reloadImg: true,
      imgSrc: ""
    });
    this.props.getReports(selectedOption.id);
  };
  componentDidUpdate() {
    let x, y, left, top, width, height, scaleFactor;

    const { loadWorkspaceAttribs, selectedWorkspace, reloadImg } = this.state;
    const {
      workspaceAlias,
      hideWorkspace,
      disableReportCreation,
      enableWorkspaceBookmarks,
      enablePersonalWorkspaceBookmarks,
      loading,
      selectedReport
    } = this.props;

    if (loadWorkspaceAttribs && selectedWorkspace !== null && !loading) {
      this.workspaceAlias.current.value = workspaceAlias;
      this.setState({
        loadWorkspaceAttribs: false,
        hideWorkspace,
        disableReportCreation,
        enableWorkspaceBookmarks,
        enablePersonalWorkspaceBookmarks
      });
    }
    if (
      selectedReport !== undefined &&
      selectedReport !== null &&
      reloadImg &&
      !loading
    ) {
      if (
        selectedReport.exportDims === undefined ||
        selectedReport.exportDims === null
      ) {
        x = 152;
        y = 152;
        left = 0;
        top = 0;
        width = 875;
        height = 483;

        scaleFactor = 0.75;
      } else if (Object.entries(selectedReport.exportDims).length === 1) {
        x = 152;
        y = 152;
        left = 0;
        top = 0;
        width = 875;
        height = 483;

        scaleFactor = 0.75;
      } else {
        x = selectedReport.exportDims.x;
        y = selectedReport.exportDims.y;
        left = selectedReport.exportDims.left;
        top = selectedReport.exportDims.top;
        width = selectedReport.exportDims.width;
        height = selectedReport.exportDims.height;

        scaleFactor = selectedReport.exportDims.scaleFactor;
      }

      this.setState({
        x,
        left,
        y,
        top,
        height,
        width,
        scaleFactor,
        reloadImg: false,
        imgSrc: selectedReport.img
      });
    }
  }
  componentDidMount() {
    this.props.getAllWorkspaces();
  }

  uploadCallback(blobInfo, success, failure) {
    let file = blobInfo.blob;

    //const xhr = new XMLHttpRequest();
    //console.log(file);
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/uploadImage`
    );
    xhr.setRequestHeader("ClientToken", localStorage.getItem("clientToken"));
    var data = new FormData();
    data.append("image", blobInfo.blob());
    data.append("client", this.props.selectedClient.value);
    data.size = file.size;
    xhr.send(data);
    xhr.addEventListener("load", () => {
      const response = JSON.parse(xhr.responseText);
      success(response.data.link);
    });
    xhr.addEventListener("error", () => {
      const error = JSON.parse(xhr.responseText);
      failure(error);
      return;
    });
  }

  handleselectedFile = event => {
    event.preventDefault();

    const data = new FormData();
    data.append("image", event.target.files[0]);
    data.append("name", "Upload");

    data.append("workspace", this.state.selectedWorkspace.name);
    data.append("width", 200);
    data.append("height", 100);

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/uploadAndResizeImage`,
        data,
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        this.setState({
          imgSrc: res.data.data.link,
          imgHeight: res.data.data.height,
          imgWidth: res.data.data.width
        });

        let sasRm = res.data.data.link.split("?")[0];
        let sasToken = res.data.data.link.split("?")[1];

        this.props.saveImg(
          this.state.selectedWorkspace,
          this.props.selectedReport,
          sasRm,
          sasToken
        );
        //console.log(res.statusText);
      });
  };
  clickHandler(event, report, index) {
    let desc,
      allowClone,
      allowDelete,
      allowSave,
      showFilterPane,
      showBookmarks,
      featured,
      x,
      y,
      left,
      top,
      width,
      height,
      imgSrc,
      disableCrop,
      useManualCrop,
      userCrop,
      scaleFactor;

    if (report.exportDims === undefined || report.exportDims === null) {
      x = 152;
      y = 152;
      left = 0;
      top = 0;
      width = 875;
      height = 483;
      disableCrop = false;
      useManualCrop = false;
      scaleFactor = 0.75;
      userCrop = false;
    } else if (Object.entries(report.exportDims).length === 1) {
      x = 152;
      y = 152;
      left = 0;
      top = 0;
      width = 875;
      height = 483;
      useManualCrop = false;
      disableCrop = report.exportDims.disableCrop;
      scaleFactor = 0.75;
      userCrop = false;
    } else {
      x = report.exportDims.x;
      y = report.exportDims.y;
      left = report.exportDims.left;
      top = report.exportDims.top;
      width = report.exportDims.width;
      height = report.exportDims.height;
      disableCrop = report.exportDims.disableCrop;
      useManualCrop = report.exportDims.useManualCrop;
      scaleFactor = report.exportDims.scaleFactor;
      userCrop = report.exportDims.userCrop;
    }
    if (
      report.featured === undefined ||
      report.featured === null ||
      report.featured === false
    ) {
      featured = false;
    } else {
      featured = true;
    }

    if (report.img === undefined || report.img === null) {
      imgSrc = "";
    } else {
      imgSrc = report.img;
    }
    if (report.description === undefined || report.description === null) {
      desc = "";
    } else {
      desc = report.description;
    }
    if (report.allowDelete === undefined || report.allowDelete === null) {
      allowDelete = false;
    } else {
      allowDelete = report.allowDelete;
    }
    if (report.allowSave === undefined || report.allowSave === null) {
      allowSave = false;
    } else {
      allowSave = report.allowSave;
    }
    if (report.allowClone === undefined || report.allowClone === null) {
      allowClone = false;
    } else {
      allowClone = report.allowClone;
    }
    if (report.showFilterPane === undefined || report.showFilterPane === null) {
      showFilterPane = false;
    } else {
      showFilterPane = report.showFilterPane;
    }
    if (report.showBookmarks === undefined || report.showBookmarks === null) {
      showBookmarks = false;
    } else {
      showBookmarks = report.showBookmarks;
    }
    this.props.setSelectedReport(report, index, desc);
    this.setState({
      // selectedReportDescription: desc,
      selectedAllowClone: allowClone,
      selectedAllowDelete: allowDelete,
      selectedAllowSave: allowSave,
      selectedShowFilterPane: showFilterPane,
      selectedShowBookmarks: showBookmarks,
      // selectedReport: report,
      // selectedReportIdx: index,
      featured,
      imgSrc,
      x,
      y,
      left,
      top,
      height,
      width,
      disableCrop,
      useManualCrop,
      scaleFactor,
      userCrop
    });
  }
  // updateDescription(evt) {
  //   const { reports, selectedReport, selectedReportIdx } = this.props;
  //   // const { selectedReportIdx } = this.state;
  //   // this.setState({
  //   //   selectedReportDescription: evt.target.value
  //   // });
  //   this.props.setSelectedReport(
  //     selectedReport,
  //     selectedReportIdx,
  //     evt.target.value
  //   );
  //   reports[selectedReportIdx].description = evt.target.value;
  // }
  updateDescription(evt) {
    const { reports, selectedReport } = this.props;
    const updatedDescription = evt.target.value;
    const updatedReportIndex = reports.findIndex(
      report => report._id === selectedReport._id
    );
    if (updatedReportIndex !== -1) {
      // Create a new report object with the updated description
      const updatedReport = {
        ...reports[updatedReportIndex],
        description: updatedDescription
      };

      // Update the selected report description in the local state
      this.props.setSelectedReport(
        updatedReport,
        updatedReportIndex,
        updatedDescription
      );
    }
    reports[updatedReportIndex].description = updatedDescription;
  }
  handleInputChange() {
    this.props.filterReports(this.search.value, this.props.reports);
  }
  inputChangeHandler(evt, type) {
    let report = this.props.selectedReport;
    let val;
    if (evt.target.value) {
      if (type === "scaleFactor") {
        val = parseFloat(evt.target.value);
      } else if (
        type === "left" ||
        type === "right" ||
        type === "x" ||
        type === "y" ||
        type === "height" ||
        type === "width"
      ) {
        val = parseInt(evt.target.value, 10);
      } else {
        val = evt.target.value;
      }
    }

    report.exportDims[type] = val;
    this.setState({ [type]: val });
  }
  saveHandler() {
    if (this.props.selectedReport !== null) {
      this.props.reports.forEach(report => {
        if (
          report.exportDims !== undefined &&
          report.exportDims !== null &&
          !report.exportDims.useManualCrop
        ) {
          let disableCrop = report.exportDims.disableCrop;
          report.exportDims = {
            disableCrop
          };
        } else if (report.id === this.props.selectedReport.id) {
          if (report.exportDims !== null && report.exportDims !== undefined) {
            if (
              report.exportDims.x === undefined ||
              report.exportDims.x === null
            ) {
              report.exportDims.x = this.state.x;
            }
            if (
              report.exportDims.y === undefined ||
              report.exportDims.y === null
            ) {
              report.exportDims.y = this.state.y;
            }
            if (
              report.exportDims.left === undefined ||
              report.exportDims.left === null
            ) {
              report.exportDims.left = this.state.left;
            }
            if (
              report.exportDims.top === undefined ||
              report.exportDims.top === null
            ) {
              report.exportDims.top = this.state.top;
            }
            if (
              report.exportDims.width === undefined ||
              report.exportDims.width === null
            ) {
              report.exportDims.width = this.state.width;
            }
            if (
              report.exportDims.height === undefined ||
              report.exportDims.height === null
            ) {
              report.exportDims.height = this.state.height;
            }
            if (
              report.exportDims.scaleFactor === undefined ||
              report.exportDims.scaleFactor === null
            ) {
              report.exportDims.scaleFactor = this.state.scaleFactor;
            }
            if (
              report.exportDims.userCrop === undefined ||
              report.exportDims.userCrop === null
            ) {
              report.exportDims.userCrop = this.state.userCrop;
            }
          }
        }
      });
    }
    this.props.saveReportDescs(
      this.state.selectedWorkspace,
      this.props.reports,
      this.workspaceAlias.current.value,
      this.state.hideWorkspace,
      this.state.disableReportCreation,
      this.state.enableWorkspaceBookmarks,
      this.state.enablePersonalWorkspaceBookmarks
    );
    toast.success(
      // eslint-disable-next-line quotes
      'Workspace "' + this.state.selectedWorkspace.name + '" saved.'
    );
  }
  checkboxChangeHandler(event, report, type) {
    const {
      selectedAllowSave,
      selectedAllowClone,
      selectedAllowDelete,
      selectedShowFilterPane,
      featured,
      useManualCrop,
      disableCrop,
      selectedShowBookmarks
    } = this.state;
    if (report.exportDims === undefined || report.exportDims === null) {
      report.exportDims = {};
    }
    if (type === "edit") {
      if (selectedAllowSave && selectedAllowDelete) {
        report.allowDelete = !selectedAllowDelete;

        this.setState({ selectedAllowDelete: !selectedAllowDelete });
      }
      report.allowSave = !selectedAllowSave;
      this.setState({ selectedAllowSave: !selectedAllowSave });
    } else if (type === "clone") {
      report.allowClone = !selectedAllowClone;
      this.setState({ selectedAllowClone: !selectedAllowClone });
    } else if (type === "filter") {
      report.showFilterPane = !selectedShowFilterPane;
      this.setState({ selectedShowFilterPane: !selectedShowFilterPane });
    } else if (type === "bookmarks") {
      report.showBookmarks = !selectedShowBookmarks;
      this.setState({ selectedShowBookmarks: !selectedShowBookmarks });
    } else if (type === "featuredReport") {
      report.featured = !featured;
      this.setState({ featured: !featured });
    } else if (type === "useManualCrop") {
      this.setState({ useManualCrop: !useManualCrop });
      report.exportDims.useManualCrop = !useManualCrop;
      this.setState({ userCrop: useManualCrop }); //revert user crop if previously cropped
      report.exportDims.userCrop = useManualCrop;
    } else if (type === "disableCrop") {
      this.setState({ disableCrop: !disableCrop });
      report.exportDims.disableCrop = !disableCrop;
    } else {
      report.allowDelete = !selectedAllowDelete;
      report.allowSave = true;
      this.setState({
        selectedAllowSave: true,
        selectedAllowDelete: !selectedAllowDelete
      });
    }
    this.props.updateReport(report);
  }
  applyToWorkspace() {
    this.props.reports.forEach(report => {
      if (!this.state.useManualCrop) {
        report.exportDims = {
          disableCrop: this.state.disableCrop
        };
      } else {
        report.exportDims = {
          x: this.state.x,
          y: this.state.y,
          left: this.state.left,
          top: this.state.top,
          width: this.state.width,
          height: this.state.height,
          disableCrop: this.state.disableCrop,
          useManualCrop: this.state.useManualCrop,
          scaleFactor: this.state.scaleFactor,
          userCrop: this.state.userCrop
        };
      }
    });
  }
  removeImgHandler() {
    this.props.saveImg(
      this.state.selectedWorkspace,
      this.props.selectedReport,
      "",
      ""
    );
    this.setState({ imgSrc: "" });
  }
  renderStuff(rpt) {
    console.log(rpt);
  }
  renderTab() {
    const {
      searchReports,
      loading,
      error,
      selectedReport,
      selectedReportDescription
    } = this.props;
    const {
      // selectedAllowSave,
      // selectedAllowClone,
      // selectedAllowDelete,
      // selectedShowFilterPane,
      // featured,
      //disableCrop,
      //useManualCrop,
      x,
      y,
      left,
      top,
      width,
      height,
      scaleFactor
    } = this.state;

    let useManualCrop = false;
    if (selectedReport !== null) {
      if (
        selectedReport.exportDims !== null &&
        selectedReport.exportDims !== undefined
      ) {
        if (
          selectedReport.exportDims.useManualCrop !== undefined &&
          selectedReport.exportDims.useManualCrop !== null
        ) {
          useManualCrop = selectedReport.exportDims.useManualCrop;
        } else {
          useManualCrop = false;
        }
      }
    }

    if (!loading && error === null) {
      return (
        <div style={{ display: "inline-flex", width: "100%" }}>
          <div style={{ width: "500px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "0px"
              }}
            >
              <div
                className="number-circle"
                style={{ marginBottom: "0px", alignSelf: "center" }}
              >
                1
              </div>
              <div
                className="config-subtitle"
                style={{ margin: "10px 0 10px 0" }}
              >
                Select a Report
              </div>
            </div>
            <div style={{ width: "80%", marginBottom: "10px" }}>
              <input
                className="input-box"
                placeholder="search"
                ref={input => (this.search = input)}
                onChange={this.handleInputChange}
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                height: "465px",
                overflowY: "scroll",
                border: "1px solid #CCC"
              }}
            >
              {searchReports.map((report, index) => {
                return (
                  <div
                    className="report-name"
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "14px"
                      //whiteSpace: "nowrap"
                    }}
                    key={index}
                    onClick={event => {
                      this.clickHandler(event, report, index);
                    }}
                  >
                    <span
                      className={
                        selectedReport && selectedReport._id === report._id
                          ? "report-span report-selected"
                          : "report-span"
                      }
                    >
                      <ReportIcon
                        height={"16px"}
                        className={"report-icon"}
                        color={"rgba(121, 121, 121, 1)"}
                        viewBox={"0 -1 24 24"}
                      />
                      {report.name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="spacer" style={{ width: "10%", display: "flex" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LeftArrow
                height={"80px"}
                color={"rgba(186,186,186,1)"}
                style={{
                  marginLeft: "20px",
                  transform: "rotate(180deg)"
                }}
              />
            </div>
          </div>
          {selectedReport && (
            <div style={{}}>
              {this.renderStuff(selectedReport)}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "0px"
                }}
              >
                <div
                  className="number-circle"
                  style={{ marginBottom: "0px", alignSelf: "center" }}
                >
                  2
                </div>
                <div
                  className="config-subtitle"
                  style={{ margin: "10px 0 10px 0" }}
                >
                  Configure Report Settings
                </div>
              </div>
              <div
                className="config-subtitle"
                style={{ margin: "8px 0 10px 0" }}
              >
                Report Description
              </div>
              <textarea
                className="report-description-input"
                value={selectedReportDescription}
                onChange={evt => {
                  this.updateDescription(evt);
                }}
              />
              {selectedReport.type === "powerBI" && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="config-subtitle"
                    style={{ margin: "10px 0 10px 0" }}
                  >
                    Report User Permission
                  </div>
                  <div>
                    <Checkbox
                      checked={selectedReport.allowSave}
                      ref={i => (this.allowSave = i)}
                      onChange={event => {
                        this.checkboxChangeHandler(
                          event,
                          selectedReport,
                          "edit"
                        );
                      }}
                    />
                    <span style={{ marginLeft: "4px" }}>Allow User Edit</span>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <Checkbox
                      checked={selectedReport.allowDelete}
                      ref={i => (this.allowDelete = i)}
                      onChange={event => {
                        this.checkboxChangeHandler(
                          event,
                          selectedReport,
                          "delete"
                        );
                      }}
                    />
                    <span style={{ marginLeft: "4px" }}>Allow User Delete</span>
                  </div>
                  <div>
                    <Checkbox
                      checked={selectedReport.allowClone}
                      ref={i => (this.allowClone = i)}
                      onChange={event => {
                        this.checkboxChangeHandler(
                          event,
                          selectedReport,
                          "clone"
                        );
                      }}
                    />
                    <span style={{ marginLeft: "4px" }}>Allow User Clone</span>
                  </div>
                  <div>
                    <Checkbox
                      checked={selectedReport.showFilterPane}
                      ref={i => (this.showFilterPane = i)}
                      onChange={event => {
                        this.checkboxChangeHandler(
                          event,
                          selectedReport,
                          "filter"
                        );
                      }}
                    />
                    <span style={{ marginLeft: "4px" }}>Show Filter Pane</span>
                  </div>
                  <div>
                    <Checkbox
                      checked={selectedReport.showBookmarks}
                      ref={i => (this.showBookmarks = i)}
                      onChange={event => {
                        this.checkboxChangeHandler(
                          event,
                          selectedReport,
                          "bookmarks"
                        );
                      }}
                    />
                    <span style={{ marginLeft: "4px" }}>
                      Show Bookmarks Pane
                    </span>
                  </div>
                </div>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className="config-subtitle"
                  style={{ margin: "10px 0 10px 0" }}
                >
                  Home Page Settings
                </div>
                <div>
                  <Checkbox
                    checked={selectedReport.featured}
                    ref={i => (this.featuredReport = i)}
                    onChange={event => {
                      this.checkboxChangeHandler(
                        event,
                        selectedReport,
                        "featuredReport"
                      );
                    }}
                  />
                  <span style={{ marginLeft: "4px" }}>
                    Featured Report (shows in the featured section of home page)
                  </span>
                </div>
                <div
                  className="config-subtitle"
                  style={{ margin: "10px 0 10px 0" }}
                >
                  Report Thumbnail
                </div>
                <div>(image will resize to 200 x 100px)</div>
                <div
                  style={{
                    height: "100px",
                    width: "200px",
                    border: "1px solid gray",
                    marginBottom: "5px",
                    overflow: "hidden"
                  }}
                >
                  {this.state.imgSrc !== "" && (
                    <img
                      // style={{
                      //   height: this.state.imgHeight + "px",
                      //   width: this.state.imgWidth + "px"
                      // }}
                      src={this.state.imgSrc}
                      alt="logo"
                    />
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  <label
                    style={{
                      marginRight: "20px",
                      border: "1px solid gray",
                      padding: "2px",
                      cursor: "pointer"
                    }}
                    htmlFor="upload"
                  >
                    Upload
                  </label>
                  <input
                    hidden={true}
                    id="upload"
                    ref
                    type="file"
                    disabled={
                      this.props.selectedClient === null ? "disabled" : ""
                    }
                    name="Upload"
                    onChange={this.handleselectedFile}
                    onClick={event => {
                      event.target.value = null;
                    }}
                  />
                  <div
                    style={{
                      border: "1px solid gray",
                      padding: "2px",
                      cursor: "pointer",
                      height: "30px"
                    }}
                    onClick={this.removeImgHandler}
                  >
                    Remove
                  </div>
                </div>
                {selectedReport.type === "tableau" && (
                  <div
                    className="ppt-builder-conf"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px"
                    }}
                  >
                    <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
                      PowerPoint Builder Configuration
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <ReportCheckbox
                        index={"disableCrop1"}
                        report={selectedReport}
                        checkedValue="disableCrop"
                        checkboxChangeHandler={(event, report) => {
                          this.checkboxChangeHandler(
                            event,
                            report,
                            "disableCrop"
                          );
                        }}
                      />
                      Disable "Crop & Make Default" button option
                    </div>
                    <div style={{ fontSize: "11px" }}>
                      This setting will hide the Crop & Make Default feature
                      from users for this report, preventing them from modfiying
                      the settings below by saving a new default crop area
                    </div>
                    <hr style={{ width: "100%", height: "5px" }} />
                    <div style={{ marginBottom: "5px" }}>
                      <Checkbox
                        style={{ paddingRight: "5px" }}
                        checked={useManualCrop}
                        ref={i => (this.useManualCrop = i)}
                        onChange={event => {
                          this.checkboxChangeHandler(
                            event,
                            selectedReport,
                            "useManualCrop"
                          );
                        }}
                      />
                      Use Manual Crop Settings
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "5px"
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>
                        Configure Report Crop Area
                      </div>
                      <div
                        style={{
                          color: "rgba(0, 126, 167, 1)",
                          textDecoration: "underline",
                          marginLeft: "100px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          if (useManualCrop) {
                            this.setState({
                              x: 152,
                              y: 152,
                              left: 0,
                              top: 0,
                              width: 875,
                              height: 483,
                              scaleFactor: 0.75,
                              userCrop: false
                            });
                          }
                        }}
                      >
                        reset to default values
                      </div>
                    </div>
                    <div
                      className="config-item"
                      style={{ marginBottom: "5px" }}
                    >
                      <div style={{ marginBottom: "5px" }}>
                        PPT Image X-Offset
                      </div>
                      <input
                        style={{ marginBottom: "5px" }}
                        onChange={evt => {
                          this.inputChangeHandler(evt, "x");
                        }}
                        type="number"
                        disabled={!useManualCrop}
                        ref={input => (this.xOffset = input)}
                        value={x}
                      />
                      <div style={{ marginBottom: "5px", fontSize: "11px" }}>
                        The X-Offset placement of the image when placed on a PPT
                        Slide
                      </div>
                    </div>
                    <div
                      className="config-item"
                      style={{ marginBottom: "5px" }}
                    >
                      <div style={{ marginBottom: "5px" }}>
                        PPT Image Y-Offset
                      </div>
                      <input
                        style={{ marginBottom: "5px" }}
                        onChange={evt => {
                          this.inputChangeHandler(evt, "y");
                        }}
                        type="number"
                        disabled={!useManualCrop}
                        ref={input => (this.yOffset = input)}
                        value={y}
                      />
                      <div style={{ marginBottom: "5px", fontSize: "11px" }}>
                        The Y-Offset placement of the image when placed on a PPT
                        Slide
                      </div>
                    </div>
                    <div
                      className="config-item"
                      style={{ marginBottom: "5px" }}
                    >
                      <div style={{ marginBottom: "5px" }}>
                        PPT Image Crop X Origin
                      </div>
                      <input
                        style={{ marginBottom: "5px" }}
                        onChange={evt => {
                          this.inputChangeHandler(evt, "left");
                        }}
                        type="number"
                        disabled={!useManualCrop}
                        ref={input => (this.xOrigin = input)}
                        value={left}
                      />
                      <div style={{ marginBottom: "5px", fontSize: "11px" }}>
                        The top-left coordinate, in pixels, of the crop area
                        (x-axis value)
                      </div>
                    </div>
                    <div
                      className="config-item"
                      style={{ marginBottom: "5px" }}
                    >
                      <div style={{ marginBottom: "5px" }}>
                        PPT Image Crop Y Origin
                      </div>
                      <input
                        style={{ marginBottom: "5px" }}
                        onChange={evt => {
                          this.inputChangeHandler(evt, "top");
                        }}
                        type="number"
                        disabled={!useManualCrop}
                        ref={input => (this.yOrigin = input)}
                        value={top}
                      />
                      <div style={{ marginBottom: "5px", fontSize: "11px" }}>
                        The top-left coordinate, in pixels, of the crop area
                        (y-axis value)
                      </div>
                    </div>
                    <div
                      className="config-item"
                      style={{ marginBottom: "5px" }}
                    >
                      <div style={{ marginBottom: "5px" }}>
                        PPT Image Crop Width
                      </div>
                      <input
                        style={{ marginBottom: "5px" }}
                        onChange={evt => {
                          this.inputChangeHandler(evt, "width");
                        }}
                        type="number"
                        disabled={!useManualCrop}
                        ref={input => (this.cropWidth = input)}
                        value={width}
                      />
                      <div style={{ marginBottom: "5px", fontSize: "11px" }}>
                        The width in pixels to crop the image when exported to a
                        PPT Slide
                      </div>
                    </div>
                    <div
                      className="config-item"
                      style={{ marginBottom: "5px" }}
                    >
                      <div style={{ marginBottom: "5px" }}>
                        PPT Image Crop Height
                      </div>
                      <input
                        style={{ marginBottom: "5px" }}
                        onChange={evt => {
                          this.inputChangeHandler(evt, "height");
                        }}
                        type="number"
                        disabled={!useManualCrop}
                        ref={input => (this.cropHeight = input)}
                        value={height}
                      />
                      <div style={{ marginBottom: "5px", fontSize: "11px" }}>
                        The height in pixels to crop the image when exported to
                        a PPT Slide
                      </div>
                    </div>
                    <div
                      className="config-item"
                      style={{ marginBottom: "5px" }}
                    >
                      <div style={{ marginBottom: "5px" }}>
                        PPT Image Scale Factor
                      </div>
                      <input
                        style={{ marginBottom: "5px" }}
                        onChange={evt => {
                          this.inputChangeHandler(evt, "scaleFactor");
                        }}
                        type="number"
                        disabled={!useManualCrop}
                        ref={input => (this.scaleFactor = input)}
                        value={scaleFactor}
                      />
                      <div style={{ marginBottom: "5px", fontSize: "11px" }}>
                        Scales the image to be larger or smaller than the
                        captured image when added to PPT. Use values between 0
                        and 1 to make the image smaller.
                      </div>
                    </div>
                    <hr style={{ width: "100%", height: "5px" }} />
                    <div
                      className="ta-button ta-button-primary"
                      style={{ width: "165px" }}
                      onClick={() => {
                        this.applyToWorkspace();
                      }}
                    >
                      Apply to Workspace
                    </div>
                    <div style={{ fontSize: "11px" }}>
                      Apply the above settings to all reports in the selected
                      workspace. Setting must still be <b>SAVED</b> using the
                      save button
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      );
    } else if (error !== null) {
      return <div>This Workspace does not exist in Power BI</div>;
    }
  }
  createThumbnails() {
    let isPowerBI = false;
    if (this.props.reports !== null) {
      if (this.props.reports[0].type === "powerBI") {
        isPowerBI = true;
      }
      if (isPowerBI) {
        axios
          .post(
            `${process.env.REACT_APP_OVIS_SERVER}/api/powerBI/createThumbnails`,
            {
              workspace: this.state.selectedWorkspace
            },
            {
              headers: {
                ClientToken: localStorage.getItem("clientToken")
              }
            }
          )
          .then(res => {
            alert("Adding Thumbnails");
            //console.log(res.statusText);
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_OVIS_SERVER}/api/tableau/createThumbnails`,
            {
              workspace: this.state.selectedWorkspace
            },
            {
              headers: {
                ClientToken: localStorage.getItem("clientToken")
              }
            }
          )
          .then(res => {
            alert("Adding Thumbnails");
            //console.log(res.statusText);
          });
      }
    }
  }

  render() {
    const { allWorkspaces } = this.props;
    const { selectedWorkspace } = this.state;
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div className="page-title">Report Metadata Configuration</div>
        <div style={{ borderBottom: "1px solid #CCC", paddingBottom: "16px" }}>
          {/* <div> */}
          <div className="workspace" style={{}}>
            <div className="client-text">Workspace:</div>
            <div style={{ width: "500px" }}>
              <Select
                className="dropdown"
                value={selectedWorkspace}
                onChange={this.handleChange}
                options={allWorkspaces}
              />
            </div>
            {/* </div> */}
            {/* </div> */}
            <div className="action-buttons">
              <div
                className="ta-button ta-button-primary"
                onClick={this.saveHandler}
              >
                Save
              </div>
              <div className="ta-button ta-button-secondary">Cancel</div>
            </div>
          </div>
        </div>
        {selectedWorkspace !== null ? (
          <div style={{ overflow: "auto" }}>
            <div>
              {selectedWorkspace !== null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px"
                  }}
                >
                  <div style={{ marginRight: "5px" }}>Workspace Alias</div>
                  <input style={{ width: "300px" }} ref={this.workspaceAlias} />
                </div>
              ) : null}
              {selectedWorkspace !== null ? (
                <div
                  style={{
                    marginTop: "2px"
                  }}
                  className="instruction-text"
                >
                  The workspace will be displayed using this name in the portal
                  (rather than the name created in Power BI or Tableau)
                </div>
              ) : null}
              {selectedWorkspace !== null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px"
                  }}
                >
                  <div>
                    <Checkbox
                      checked={this.state.hideWorkspace}
                      ref={i => (this.hideWorkspace = i)}
                      onChange={event => {
                        this.setState({
                          hideWorkspace: !this.state.hideWorkspace
                        });
                      }}
                      style={{ marginRight: "5px" }}
                    />
                    Hide Workspace in Portal
                  </div>

                  <div
                    style={{
                      marginTop: "2px",

                      marginBottom: "10px"
                    }}
                    className="instruction-text"
                  >
                    The Workspace will be hidden from users by enabling this
                    configuration. Used when displaying the workspace reports
                    using custom applications
                  </div>
                  <div>
                    <Checkbox
                      checked={this.state.enableWorkspaceBookmarks}
                      ref={i => (this.enableWorkspaceBookmarks = i)}
                      onChange={event => {
                        this.setState({
                          enableWorkspaceBookmarks:
                            !this.state.enableWorkspaceBookmarks
                        });
                      }}
                      style={{ marginRight: "5px" }}
                    />
                    Enable Bookmarks for all Reports in Workspace
                  </div>
                  <div>
                    <Checkbox
                      checked={this.state.enablePersonalWorkspaceBookmarks}
                      ref={i => (this.enablePersonalWorkspaceBookmarks = i)}
                      onChange={event => {
                        this.setState({
                          enablePersonalWorkspaceBookmarks:
                            !this.state.enablePersonalWorkspaceBookmarks
                        });
                      }}
                      style={{ marginRight: "5px" }}
                    />
                    Enable Personal Bookmarks for all Reports in Workspace
                  </div>

                  <div>
                    <Checkbox
                      checked={this.state.disableReportCreation}
                      ref={i => (this.disableReportCreation = i)}
                      onChange={event => {
                        // console.log(this.state.disableReportCreation);
                        this.setState({
                          disableReportCreation:
                            !this.state.disableReportCreation
                        });
                      }}
                      style={{ marginRight: "5px" }}
                    />
                    Disable Report Creation & Editing
                  </div>
                </div>
              ) : null}
            </div>
            {selectedWorkspace !== null ? (
              <div style={{ marginTop: "20px" }}>
                <div
                  style={{ width: "fit-content", marginLeft: "0" }}
                  className="ta-button ta-button-primary"
                  onClick={this.createThumbnails}
                >
                  Create Report Thumbnails
                </div>
                <div
                  className="section-heading"
                  style={{ marginTop: "20px", maxWidth: "80%" }}
                >
                  Report Configuration
                </div>
                <div
                  className="instruction-text"
                  style={{ paddingTop: "5px", paddingBottom: "20px" }}
                >
                  Choose a report on the left and add a description and set
                  other report configurations on the right panel.
                </div>
              </div>
            ) : null}

            {this.renderTab()}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reports: state.reportReducer.reports,
  selectedReport: state.reportReducer.selectedReport,
  selectedReportIdx: state.reportReducer.selectedReportIdx,
  selectedReportDescription: state.reportReducer.selectedReportDescription,
  searchReports: state.reportReducer.searchReports,
  workspaceAlias: state.reportReducer.workspaceAlias,
  hideWorkspace: state.reportReducer.hideWorkspace,
  disableReportCreation: state.reportReducer.disableReportCreation,
  enableWorkspaceBookmarks: state.reportReducer.enableWorkspaceBookmarks,
  enablePersonalWorkspaceBookmarks:
    state.reportReducer.enablePersonalWorkspaceBookmarks,
  error: state.reportReducer.error,
  loading: state.reportReducer.loading,
  allWorkspaces: state.workspaceReducer.allWorkspaces,
  wsLoading: state.workspaceReducer.loading
});
const mapDispatchToProps = dispatch => ({
  getReports(workspace) {
    dispatch(getReports(workspace));
  },
  getAllWorkspaces() {
    dispatch(getAllWorkspaces());
  },
  setWorkspace(workspace) {
    dispatch(setWorkspace(workspace));
  },
  saveReportDescs(
    workspace,
    reports,
    alias,
    hideWorkspace,
    disableReportCreation,
    enableWorkspaceBookmarks,
    enablePersonalWorkspaceBookmarks
  ) {
    dispatch(
      saveReportDescs(
        workspace,
        reports,
        alias,
        hideWorkspace,
        disableReportCreation,
        enableWorkspaceBookmarks,
        enablePersonalWorkspaceBookmarks
      )
    );
  },
  filterReports(filter, reports) {
    dispatch(filterReports(filter, reports));
  },
  updateReport(report) {
    dispatch(updateReport(report));
  },
  saveImg(workspace, report, source, sasToken) {
    dispatch(saveImg(workspace, report, source, sasToken));
  },
  setSelectedReport(report, idx, desc) {
    dispatch(setSelectedReport(report, idx, desc));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportMetadata);
