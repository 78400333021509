import { RightChevron } from "../lib/icons";
import React, { useState } from "react";
export const DashboardRptItem = ({
  workspace,
  idx,
  checkboxChangeHandler,
  selectedDashboardReports
}) => {
  const [collapsed, setCollapsed] = useState(true);
  console.log(workspace);
  const isReportSelected = (workspaceId, reportId) => {
    return selectedDashboardReports.some(
      ws =>
        ws.workspace === workspaceId &&
        ws.reports.some(rpt => rpt._id === reportId)
    );
  };
  function renderReports() {
    if (!collapsed) {
      return workspace.reports.map((item, idx) => {
        return (
          <div
            key={idx}
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "20px"
            }}
          >
            <input
              type="checkbox"
              onChange={e => {
                checkboxChangeHandler(e, item, "dashboardReports");
              }}
              checked={isReportSelected(workspace._id, item._id)}
            />
            <div>{item.name}</div>
          </div>
        );
      });
    } else {
      return null;
    }
  }
  return (
    <div
      key={"STUFF" + idx}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <RightChevron
          transform={collapsed ? "rotate(270,12,12)" : "-rotate(90,12,12)"}
          height={"20px"}
          // width={"24px"}
          color={"rgba(88, 89, 91, 1)"}
          viewBox={"-1 2 24 24"}
        />
        <div key={"iItem" + workspace.id + idx}>{workspace.name}</div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {renderReports()}
      </div>
    </div>
  );
};
