import axios from "axios";
import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  workspaces: [],
  loading: true,
  error: null,
  allWorkspaces: [],
  searchAllWorkspaces: [],
  activeWorkspaces: [],
  activeLoading: false,
  allLoading: true,
  selectedWorkspace: null,
  searchWorkspaces: [],
  searchActiveWorkspaces: [],
  searchGroupWorkspaces: []
};

/* -------------------- ACTION TYPES -------------------- */
const SET_WORKSPACES_SUCCESS = "SET_WORKSPACES_SUCCESS";
const SET_ALL_WORKSPACE_STATUS = "SET_ALL_WORKSPACE_STATUS";
const RESET_ALL_WORKSPACES_STATUS = "RESET_ALL_WORKSPACES_STATUS";
const FETCH_ALL_WORKSPACES_BEGIN = "FETCH_ALL_WORKSPACES_BEGIN";
const FETCH_ALL_WORKSPACES_SUCCESS = "FETCH_ALL_WORKSPACES_SUCCESS";
const FETCH_ALL_WORKSPACES_FAILURE = "FETCH_ALL_WORKSPACES_FAILURE";
const FETCH_ACTIVE_WORKSPACES_BEGIN = "FETCH_ACTIVE_WORKSPACES_BEGIN";
const FETCH_ACTIVE_WORKSPACES_SUCCESS = "FETCH_ACTIVE_WORKSPACES_SUCCESS";
const FETCH_ACTIVE_WORKSPACES_FAILURE = "FETCH_ACTIVE_WORKSPACES_FAILURE";
const SET_WORKSPACE_STATUS = "SET_WORKSPACE_STATUS";
const SAVE_ALL_WORKSPACES_BEGIN = "SAVE_ALL_WORKSPACES_BEGIN";
const SAVE_ALL_WORKSPACES_SUCCESS = "SAVE_ALL_WORKSPACES_SUCCESS";
const SAVE_ALL_WORKSPACES_FAILURE = "SAVE_ALL_WORKSPACES_FAILURE";
const SET_SELECTED_WORKSPACE = "SET_SELECTED_WORKSPACE";
const SET_SEARCH_WORKSPACES = "SET_SEARCH_WORKSPACES";
const SET_SEARCH_ACTIVE_WORKSPACES = "SET_SEARCH_ACTIVE_WORKSPACES";
const SET_SEARCH_GROUP_WORKSPACES = "SET_SEARCH_GROUP_WORKSPACES";

/* -------------------- ACTION CREATORS -------------------- */

const setWorkspacesSuccess = workspaces => {
  return { type: SET_WORKSPACES_SUCCESS, payload: workspaces };
};

const fetchAllWorkspacesBegin = () => ({
  type: FETCH_ALL_WORKSPACES_BEGIN
});
const fetchAllWorkspacesSuccess = allWorkspaces => {
  return { type: FETCH_ALL_WORKSPACES_SUCCESS, payload: allWorkspaces };
};
const fetchAllWorkspacesError = error => ({
  type: FETCH_ALL_WORKSPACES_FAILURE,
  payload: { error }
});
const fetchActiveWorkspacesBegin = () => ({
  type: FETCH_ACTIVE_WORKSPACES_BEGIN
});
const fetchActiveWorkspacesSuccess = workspaces => {
  return { type: FETCH_ACTIVE_WORKSPACES_SUCCESS, payload: workspaces };
};
const fetchActiveWorkspacesError = error => ({
  type: FETCH_ACTIVE_WORKSPACES_FAILURE,
  payload: { error }
});
const saveAllWorkspacesBegin = () => ({
  type: SAVE_ALL_WORKSPACES_BEGIN
});
const saveAllWorkspacesSuccess = workspaces => {
  return { type: SAVE_ALL_WORKSPACES_SUCCESS, payload: workspaces };
};
const saveAllWorkspacesError = error => ({
  type: SAVE_ALL_WORKSPACES_FAILURE,
  payload: { error }
});

const setAllWorkspaceStatus = allWorkspace => {
  return { type: SET_ALL_WORKSPACE_STATUS, payload: allWorkspace };
};
const resetAllWorkspaceStatus = allWorkspaces => {
  return { type: RESET_ALL_WORKSPACES_STATUS, payload: allWorkspaces };
};
const setSelectedWorkspace = workspace => {
  return { type: SET_SELECTED_WORKSPACE, payload: workspace };
};
const setSearchWorkspaces = ws => {
  return { type: SET_SEARCH_WORKSPACES, payload: ws };
};
const setSearchActiveWorkspaces = ws => {
  return { type: SET_SEARCH_ACTIVE_WORKSPACES, payload: ws };
};
const setSearchGroupWorkspaces = ws => {
  return { type: SET_SEARCH_GROUP_WORKSPACES, payload: ws };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const setWorkspaces = workspaces => {
  return dispatch => {
    dispatch(setWorkspacesSuccess(workspaces));
  };
};
export const refreshWorkspaces = client => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/powerBI/getGroups`,
        {
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        //console.log(response.data);
        //dispatch(refreshWorkspacesSuccess(response.data.availableWorkspaces));
      })
      .catch(error => console.log("Error Refreshing the workspaces " + error));
  };
};
export const getAllWorkspacesForClient = client => {
  return dispatch => {
    dispatch(fetchAllWorkspacesBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getAllWorkspacesForClient`,
        {
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchAllWorkspacesSuccess(response.data.allWorkspaces));
      })
      .catch(error => dispatch(fetchAllWorkspacesError(error)));
  };
};
export const getActiveWorkspaceForClient = client => {
  return dispatch => {
    dispatch(fetchActiveWorkspacesBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getActiveWorkspacesForClient`,
        {
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchActiveWorkspacesSuccess(response.data.workspaces));
      })
      .catch(error => dispatch(fetchActiveWorkspacesError(error)));
  };
};
export const getAllWorkspaces = (justPowerBI = false) => {
  return dispatch => {
    dispatch(fetchAllWorkspacesBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getAllWorkspaces`,
        {
          justPowerBI
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchAllWorkspacesSuccess(response.data.allWorkspaces));
      })
      .catch(error => dispatch(fetchAllWorkspacesError(error)));
  };
};

export const saveAllWorkspaces = (client, workspaces) => {
  return dispatch => {
    dispatch(saveAllWorkspacesBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveAllWorkspaces`,
        {
          client: client,
          workspaces
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        let newWorkspaces = workspaces.filter(item => {
          return item.checked;
        });
        dispatch(saveAllWorkspacesSuccess(newWorkspaces));
      })
      .catch(error => dispatch(saveAllWorkspacesError(error)));
  };
};

//The status is a temporary state while you are in the add all workspace dialog.
//It is updated with every click and then if you press ok it is saved.
//Otherwise it is reverted back to the original
export const setAllStatus = workspace => {
  return dispatch => {
    dispatch(setAllWorkspaceStatus(workspace));
  };
};

export const setWorkspace = workspace => {
  return dispatch => {
    dispatch(setSelectedWorkspace(workspace));
  };
};

export const resetAllStatus = workspaces => {
  return dispatch => {
    dispatch(resetAllWorkspaceStatus(workspaces));
  };
};

export const filterAllWorkspaces = (filter, workspaces) => {
  return dispatch => {
    if (!workspaces) {
      dispatch(setSearchWorkspaces([]));
      return;
    }

    if (filter === "") {
      dispatch(setSearchWorkspaces(workspaces));
    } else {
      const newWorkspaces = workspaces.filter(ws => {
        return ws.name.toLowerCase().includes(filter.toLowerCase());
      });
      dispatch(setSearchWorkspaces(newWorkspaces));
    }
  };
};
export const filterActiveWorkspaces = (filter, workspaces) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchActiveWorkspaces(workspaces));
    } else {
      let newApps = cloneDeep(workspaces);
      newApps = newApps.filter(app => {
        return app.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      });
      dispatch(setSearchActiveWorkspaces(newApps));
    }
  };
};
export const filterActiveWorkspacesWReports = (filter, workspaces) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchActiveWorkspaces(workspaces));
    } else {
      let filteredWorkspaces = cloneDeep(workspaces);
      filteredWorkspaces = filteredWorkspaces.filter(workspace => {
        // Check if the workspace name matches the filter
        const workspaceNameMatch = workspace.name
          .toLowerCase()
          .includes(filter.toLowerCase());

        // Filter and keep only matching reports
        if (workspace.reports && Array.isArray(workspace.reports)) {
          workspace.reports = workspace.reports.filter(report =>
            report.name.toLowerCase().includes(filter.toLowerCase())
          );
        }

        // Keep the workspace if its name matches or if it has any matching reports
        return (
          workspaceNameMatch ||
          (workspace.reports && workspace.reports.length > 0)
        );
      });

      dispatch(setSearchActiveWorkspaces(filteredWorkspaces));
    }
  };
};
export const filterGroupWorkspaces = (filter, workspaces) => {
  return dispatch => {
    if (!workspaces) {
      dispatch(setSearchGroupWorkspaces([]));
      return;
    }

    if (filter === "") {
      dispatch(setSearchGroupWorkspaces(workspaces));
    } else {
      const newWorkspaces = workspaces.filter(ws => {
        return ws.name.toLowerCase().includes(filter.toLowerCase());
      });
      dispatch(setSearchGroupWorkspaces(newWorkspaces));
    }
  };
};
/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_WORKSPACES_SUCCESS:
      return {
        ...state,
        workspaces: action.payload,
        searchWorkspaces: action.payload,
        loading: false
      };
    case FETCH_ALL_WORKSPACES_BEGIN:
      return { ...state, allLoading: true, error: null };
    case FETCH_ALL_WORKSPACES_SUCCESS:
      return {
        ...state,
        allWorkspaces: action.payload,
        searchAllWorkspaces: action.payload,
        allLoading: false
      };
    case FETCH_ALL_WORKSPACES_FAILURE:
      return {
        ...state,
        allLoading: false,
        error: action.payload.error
      };
    case FETCH_ACTIVE_WORKSPACES_BEGIN:
      return { ...state, activeLoading: true, error: null };
    case FETCH_ACTIVE_WORKSPACES_SUCCESS:
      return {
        ...state,
        activeWorkspaces: action.payload,
        searchActiveWorkspaces: action.payload,
        activeLoading: false
      };
    case FETCH_ACTIVE_WORKSPACES_FAILURE:
      return {
        ...state,
        activeLoading: false,
        error: action.payload.error
      };

    case SAVE_ALL_WORKSPACES_BEGIN:
      return { ...state };
    case SAVE_ALL_WORKSPACES_SUCCESS:
      let newWorkspaces = [];
      let proWorkspaces = cloneDeep(action.payload);
      proWorkspaces.forEach(item => {
        let idx = state.workspaces
          .map(workspace => {
            return workspace.id;
          })
          .indexOf(item.id);
        if (idx === -1) {
          item.checked = false;
        } else {
          item.checked = state.workspaces[idx].checked;
        }
        newWorkspaces.push(item);
        // console.log(idx);
      });

      return {
        ...state,
        workspaces: newWorkspaces,
        searchWorkspaces: newWorkspaces
      };
    case SAVE_ALL_WORKSPACES_FAILURE:
      return {
        ...state
      };
    case SET_WORKSPACE_STATUS:
      let updateWorkspaces = state.workspaces;
      let idx = state.workspaces
        .map(workspace => {
          return workspace.name;
        })
        .indexOf(action.payload.name);
      updateWorkspaces[idx] = action.payload;

      return {
        ...state,
        workspaces: updateWorkspaces
      };
    case SET_ALL_WORKSPACE_STATUS:
      let updateAllWorkspaces = state.allWorkspaces;
      let searchAllWorkspaces = state.searchAllWorkspaces;

      let allIdx = state.allWorkspaces
        .map(workspace => {
          return workspace.name;
        })
        .indexOf(action.payload.name);
      updateAllWorkspaces[allIdx] = action.payload;
      let sAllIdx = state.searchAllWorkspaces
        .map(workspace => {
          return workspace.name;
        })
        .indexOf(action.payload.name);
      searchAllWorkspaces[sAllIdx] = action.payload;

      return {
        ...state,
        allWorkspaces: updateAllWorkspaces,
        searchAllWorkspaces: searchAllWorkspaces
      };
    case RESET_ALL_WORKSPACES_STATUS:
      return {
        ...state,
        allWorkspaces: action.payload,
        searchAllWorkspaces: action.payload
      };
    case SET_SELECTED_WORKSPACE:
      return { ...state, selectedWorkspace: action.payload };
    case SET_SEARCH_WORKSPACES:
      return { ...state, searchAllWorkspaces: action.payload };
    case SET_SEARCH_GROUP_WORKSPACES:
      return { ...state, searchGroupWorkspaces: action.payload };
    case SET_SEARCH_ACTIVE_WORKSPACES:
      return { ...state, searchActiveWorkspaces: action.payload };
    default:
      return state;
  }
};
